import { NgModule } from '@angular/core';

/** ICON **/
import * as AllIcons from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';

import { NgZorroAntdModule,  NZ_ICONS } from 'ng-zorro-antd';


const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);

@NgModule({
    declarations: [],
    imports: [
        /** import ng-zorro-antd root module，you should import NgZorroAntdModule and avoid importing sub modules directly **/
        NgZorroAntdModule
    ],
    exports: [
        NgZorroAntdModule
    ],
    providers: [{ provide: NZ_ICONS, useValue: icons }],
})
export class AntDesignModule {
}
