import { AbpHttpInterceptor } from "@abp/abpHttpInterceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import * as ApiServiceProxies from "./service-proxies";
import * as ApiServiceV2Proxies from "./v2-service-proxies";

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        /*ApiServiceProxies.ChatServiceProxy,

        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,*/
        // ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        /*ApiServiceProxies.TenantServiceProxy,*/
        /*ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,*/
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        /*ApiServiceProxies.TenantRegistrationServiceProxy,*/
        /*ApiServiceProxies.HostDashboardServiceProxy,*/
        /*ApiServiceProxies.PaymentServiceProxy,*/
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        /*ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,*/
        /*ApiServiceProxies.ClinicalServiceProxy,*/
        ApiServiceProxies.CommonCategoryServiceProxy,
        /*ApiServiceProxies.DangKyChuongTrinhServiceProxy,
        ApiServiceProxies.CoSoNhapKetQuaServiceProxy,
        ApiServiceProxies.TrungTamKetQuaServiceProxy,*/

        // v2
        ApiServiceV2Proxies.CycleServiceProxy,
        ApiServiceV2Proxies.ComboboxServiceProxy,
        ApiServiceV2Proxies.ProgrammeServiceProxy,
        ApiServiceV2Proxies.EnrollmentServiceProxy,
        ApiServiceV2Proxies.CategoryAntibioticServiceProxy,
        ApiServiceV2Proxies.CategorySpeciesServiceProxy,
        ApiServiceV2Proxies.CategoryParameterServiceProxy,
        ApiServiceV2Proxies.CategoryInstrumentServiceProxy,
        ApiServiceV2Proxies.CategoryReagentServiceProxy,
        ApiServiceV2Proxies.CategoryMethodServiceProxy,
        ApiServiceV2Proxies.CategoryUnitServiceProxy,        
        ApiServiceV2Proxies.ClinicalServiceProxy,        
        ApiServiceV2Proxies.CategoryParameterCategoryServiceProxy,
        ApiServiceV2Proxies.CycleServiceProxy,
        ApiServiceV2Proxies.OrderServiceProxy,
        ApiServiceV2Proxies.AgreementServiceProxy,
        ApiServiceV2Proxies.ProfileServiceProxy,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AbpHttpInterceptor,
            multi: true
        },
        ApiServiceV2Proxies.ReportServiceProxy,
        ApiServiceV2Proxies.CategoryParameterCategoryValueServiceProxy,
        ApiServiceV2Proxies.DonViDashboardServiceProxy,
        ApiServiceV2Proxies.SamplePlanTwoServiceProxy,
        ApiServiceV2Proxies.SampleServiceProxy,
        ApiServiceV2Proxies.CongifurationChuyenMauServiceProxy,
        ApiServiceV2Proxies.EnrollmentTestServiceProxy,
        ApiServiceV2Proxies.EnrollmentTestResultServiceProxy,
        ApiServiceV2Proxies.EnrollmentTestResultMicrobiologyServiceProxy,
        ApiServiceV2Proxies.ClinicalProgrameServiceProxy,
        ApiServiceV2Proxies.TrackResultServiceProxy,
        ApiServiceV2Proxies.TrackSampleResultServiceProxy,
        ApiServiceV2Proxies.CategoryDeliveryPriceServiceProxy,
        ApiServiceV2Proxies.LiquidationServiceProxy,
        ApiServiceV2Proxies.Template8aServiceProxy,
        ApiServiceV2Proxies.HostSettingsServiceProxy,
    ]
})
export class ServiceProxyModule { }
