import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import * as $ from 'jquery';

@Injectable()
export class AppUtilityService {
    constructor(
    ) {

    }
    static  removeDau(str: string):string{
        str = str.toLowerCase();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
        str = str.replace(/ + /g, " ");
        str = str.trim();
        return str;
    }
    static  isNullOrEmpty(input : any):boolean{
        return !(typeof input!='undefined' && input && input != "");
    }
    static checkResultValidate(result: any[]): boolean{
        var f = result.filter(x=>x.result == false).length > 0 ;
        return f;
    }
    static checkResultMinMaxValidate(result: number,min:number,max:number): boolean{
        var r = (result >= min && result <=max);
        return r;
    }
    static validateEmail(email:string): boolean {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    static toCamelObject(o:any):any{
        var newO, origKey, newKey, value
        if (o instanceof Array) {
            return o.map(function (value) {
                if (typeof value === "object") {
                    value = AppUtilityService.toCamelObject(value)
                }
                return value
            })
        } else {
            newO = {}
            for (origKey in o) {
                if (o.hasOwnProperty(origKey)) {
                    newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                    value = o[origKey]
                    if (value instanceof Array || (value !== null && value.constructor === Object)) {
                        value = AppUtilityService.toCamelObject(value)
                    }
                    newO[newKey] = value
                }
            }
        }
        return newO
    }
    static convertJsonToObject(json:string):any{
        if(AppUtilityService.isNullOrEmpty(json)) return {};
        return AppUtilityService.toCamelObject(JSON.parse(json));
    }
    static  array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };
    static changeTextNodata(str){
        $(".e-emptyrow").hide();
        setTimeout(function(){
        $(".e-emptyrow td").html(str);
        $(".e-emptyrow").show();
        },50);
    }
}
