export class AppConsts {
    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'PMS'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };

    static readonly grid = {
        defaultPageSize: 10
    };
    static readonly current = {
        Year: new Date().getFullYear()
    };

    static commonEnum = {
        TYPE_CATEGORY: {
            INSTRUMENT: 1,
            REAGENT_SUPPLIER: 2,
            METHOD: 3,
            UNIT_OF_MEASUREMENT: 4,
            PARAMETER: 5,
            HINH_THUC_VAN_CHUYEN: 6
        },
        TRANG_THAI_DUYET: {
            CHO_DUYET: 1,
            DA_DUYET: 2,
            BI_TU_CHOI: -1
        },
        STATUS_ERROR_CODE: {
            DA_CO_EMAIL: -1,
            DA_CO_BUSINESSCODE: -2
        },
        TYPE_PARAMETER: {
            DINH_LUONG: 1,
            DINH_TINH: 2,
            BAN_DINH_LUONG: 3,
            NHUOM_GRAM: 4,
            DINH_DANH_VI_KHUAN: 5,
            KHANG_SINH_DO: 6,
        },
        TRANG_THAI_DANG_KY: {
            CHO_DUYET: 1,
            DA_THANH_TOAN: 2,
            BI_TU_CHOI: -1,
            GHI_NO: 3
        },
        TRANG_THAI_PHIEU_DANG_KY: {
            DA_TAO: 0,
            DA_GUI: 1,
            DA_NHAN: 2,
            DA_LAP_HOP_DONG: 3,
            DA_DUYET: 4,
            TU_CHOI: 5,
            HUY: 6,
        },

        TRANG_THAI_HOP_DONG: {
            DA_TAO: 0,
            DA_GUI: 1,
            DA_NHAN: 2,
            HUY: 3
        },

        TRANG_THAI_BIEN_BAN_THANH_LY_HOP_DONG: {
            DA_TAO: 0,
            DA_GUI: 1,
            DA_NHAN: 2,
            HUY: 3
        },

        LOAI_BIEN_BAN_THANH_LY: {
            NGHIEM_THU_THANH_LY: 0,
            MAU_08A_100: 1
        },

        HINH_THUC_VAN_CHUYEN: {
            TU_VAN_CHUYEN: 1,
            TRUNG_TAM_CHUYEN: 2,
        },

        SAMPLE_TYPE: {
            NHUOM_GRAM: 1,
            VI_KHUAN: 2,
            KHANG_SINH_DO: 3,
        },

        HOSPITAL_LEVEL: {
            HANG_1: 1,
            HANG_2: 2,
            HANG_3: 3,
            KHAC: 0
        },

        BUSINESS_TYPE: {
            NHA_NUOC: 1,
            TU_NHAN: 2
        },
        ROLE_LEVEL: {
            KHACH_HANG: 1,
            TRUNG_TAM: 2,
            NHAN_VIEN: 3
        }
    };

    static getDisplayEnum = {
        HOSPITAL_LEVEL: d => {
            switch (d) {
                case 0:
                    return 'Hạng đặc biệt';
                case 1:
                    return 'Hạng 1';
                case 2:
                    return 'Hạng 2';

                case 3:
                    return 'Hạng 3';

                case 4:
                    return 'Hạng 4';

                case 5:
                    return 'Khác';

                default:
                    return '';
            }
        },

        BUSINESS_TYPE: d => {
            switch (d) {
                case 1:
                    return 'Nhà nước';
                case 2:
                    return 'Tư nhân';
                default:
                    return '';
            }
        },

        TYPE_PARAMETER: d => {
            if (d == 1) {
                return 'Định lượng';
            } else if (d == 2) {
                return 'Định tính';
            } else if (d == 3) {
                return 'Bán định lượng';
            }
            return '';
        },

        TRANG_THAI_DUYET: d => {
            if (d == 1) {
                return 'Chờ duyệt';
            } else if (d == 2) {
                return 'Đã duyệt';
            }
            return '';
        }
    };
}
