import { Component, Injector, ViewChild } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ProfileServiceProxy, UpdateSignaturePictureInput } from "@shared/service-proxies/v2-service-proxies";
import { TokenService } from '@abp/auth/token.service';
import { ModalDirective } from "ngx-bootstrap";
import { FileItem, FileUploader, FileUploaderOptions } from "ng2-file-upload";
import { AppConsts } from "@shared/AppConsts";
import { IAjaxResponse } from "abp-ng2-module/dist/src/abpHttpInterceptor";
import { filter, finalize } from "rxjs/operators";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NzMessageService, UploadFile } from "ng-zorro-antd";
import { HttpClient, HttpRequest, HttpResponse } from "@angular/common/http";

@Component({
    selector: 'changeSignaturePictureModal',
    templateUrl: './change-signature-picture-modal.component.html'
})
export class ChangeSignaturePictureModalComponent extends AppComponentBase {

    @ViewChild('changeSignaturePictureModal') modal: ModalDirective;
    public uploader: FileUploader;
    public temporaryPictureUrl: string;
    public saving = false;
    public active = false;

    imageChangedEvent: any = '';
    currentSignatureImage: SafeResourceUrl = null;

    fileList = [];
    url: string = AppConsts.remoteServiceBaseUrl + '/Profile/UploadSignaturePicture'

    constructor(
        injector: Injector,
        private _profileService: ProfileServiceProxy,
        private _tokenService: TokenService,
        private _sanitizer: DomSanitizer,
        private http: HttpClient,
        private _nzMessageService: NzMessageService
    ) {
        super(injector);
    }


    show(): void {
        this.active = true;
        this.modal.show();
        this.loadCurrentSignatureImage();
    }

    beforeUpload = (file: UploadFile): boolean => {
        this.fileList = this.fileList.concat(file);
        return false;
    };

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    save(): void {
        if (this.fileList.length < 1) {
            this._nzMessageService.error("Bạn phải chưa chọn tệp tin để lưu");
            return;
        }

        const formData = new FormData();
        // tslint:disable-next-line:no-any
        this.fileList.forEach((file: any) => {
            formData.append('files[]', file);
        });

        abp.ui.setBusy(true);

        // You can use any AJAX library you like
        const req = new HttpRequest('POST', this.url, formData, {
            // reportProgress: true
        });

        this.http
            .request(req)
            .pipe(filter(e => e instanceof HttpResponse))
            .subscribe(
                () => {
                    abp.ui.clearBusy();
                    this.fileList = [];
                    this._nzMessageService.success("Cập nhật ảnh chữ ký thành công");
                    this.close();
                },
                () => {
                    abp.ui.clearBusy();
                    this._nzMessageService.error('Cập nhật ảnh chữ ký thật bại');
                }
            );
    }

    // load ảnh ký hiện tại
    loadCurrentSignatureImage() {
        this._profileService.loadSignatureImage().subscribe(result => {
            this.currentSignatureImage = this._sanitizer.bypassSecurityTrustResourceUrl("data:image/png;base64, " + result);
        });
    }
}