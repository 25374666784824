import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { FileDto } from '@shared/service-proxies/service-proxies';

@Injectable()
export class FileDownloadService {

    downloadTempFile(file: FileDto) {
        const url = AppConsts.remoteServiceBaseUrl + '/File/DownloadTempFile?fileType=' + file.fileType + '&fileToken=' + file.fileToken + '&fileName=' + file.fileName;
        location.href = url; //TODO: This causes reloading of same page in Firefox
    }

    // Hàm này chỉ được dùng trong trường hợp mở file PDF trên trình duyệt
    openTempFile(file: FileDto) {
        const url = AppConsts.remoteServiceBaseUrl + '/File/OpenTempFile?fileType=' + file.fileType + '&fileToken=' + file.fileToken + '&fileName=' + file.fileName;
        window.open(url, '_blank');
    }

    getTempFileUrl(file: FileDto) {
        console.log("file", file);
        return AppConsts.remoteServiceBaseUrl + '/File/OpenTempFile?fileType=' + file.fileType + '&fileToken=' + file.fileToken + '&fileName=' + file.fileName;
    }
}
