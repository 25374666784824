import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            /*new AppMenuItem(
                'Dashboard',
                'Pages.Administration.Host.Dashboard',
                'flaticon-line-graph',
                '/app/admin/hostDashboard'
            ),*/
            /*
                        new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            */
            // don-vi-areastrung-tam-ket-qua-nhap-lieu
            /*new AppMenuItem(
                'Dashboard',
                'Pages_DonViArea_Dashboard',
                'flaticon-line-graph',
                '/app/main/don-vi/dashboard'
            ),*/
            new AppMenuItem('Customer', 'Pages.DuyetDangKy.XetNghiem', 'flaticon-users', '', [
                new AppMenuItem(
                    'Clinical',
                    'Pages.DuyetDangKy.XetNghiem',
                    '	flaticon-file',
                    '/app/main/duyet-dang-ky'
                ),
                new AppMenuItem(
                    'Tài khoản Khoa/Phòng XN Bệnh viện',
                    'Pages.XetDuyet.NhanVien',
                    'flaticon-line-graph',
                    '/app/main/tai-khoan-nhan-vien-benh-vien'
                )
            ]),
            new AppMenuItem(
                'Phòng/khoa xét nghiệm',
                'Pages_DonViArea_PhongKhoa_XetNghiem',
                'flaticon-line-graph',
                '/app/main/phong-khoa-xet-nghiem'
            ),
            // new AppMenuItem(
            //     'Tài khoản Khoa/Phòng XN Bệnh viện',
            //     'Pages.XetDuyet.NhanVien',
            //     'flaticon-line-graph',
            //     '/app/main/tai-khoan-nhan-vien-benh-vien'
            // ),
            // new AppMenuItem(
            //     'Client',
            //     'Pages.DuyetDangKy.XetNghiem',
            //     '	flaticon-file',
            //     '/app/main/duyet-dang-ky'
            // ),
            new AppMenuItem(
                'PhieuDangKy',
                'Pages_DonViArea_PhieuDangKy',
                'flaticon-file-2',
                '/app/main/don-vi/phieu-dang-ky'
            ),
            new AppMenuItem(
                'PhieuDangKy',
                'Pages_TrungTamArea_PhieuDangKy',
                'flaticon-file-2',
                '/app/main/trung-tam/phieu-dang-ky'
            ),
            new AppMenuItem('DanhSachTongHop', '', 'flaticon-list-1', '', [
                new AppMenuItem(
                    'DanhSachTongHop_HoaChat',
                    'Pages_TrungTamArea_DanhSachPhieuDangKy',
                    'la la-flask',
                    '/app/main/trung-tam/ds-tong-hop-hoa-chat'
                ),
                new AppMenuItem(
                    'DanhSachTongHop_LienHe',
                    'Pages_TrungTamArea_DanhSachPhieuDangKy',
                    'fa fa-address-book',
                    '/app/main/trung-tam/ds-tong-hop-lien-he'
                )
            ]),

            new AppMenuItem(
                'HopDong',
                'Pages_TrungTamArea_HopDong',
                'fa fa-file-contract',
                '/app/main/trung-tam/hop-dong'
            ),

            new AppMenuItem(
                'HopDong',
                'Pages_DonViArea_HopDong',
                'fa fa-file-contract',
                '/app/main/don-vi/hop-dong'
            ),

            new AppMenuItem(
                'CongNo',
                'Pages_TrungTamArea_CongNo',
                'la la-money',
                '/app/main/trung-tam/cong-no'
            ),

            new AppMenuItem(
                'ThanhLyHopDong',
                'Pages_TrungTamArea_ThanhLyHopDong',
                'fa fa-file-contract',
                '/app/main/trung-tam/thanh-ly-hop-dong'
            ),

            new AppMenuItem(
                'BangXacDinhKhoiLuongCongViec',
                'Pages_TrungTamArea_BangXacDinhKhoiLuongCongViec',
                'fa fa-file-contract',
                '/app/main/trung-tam/bieu-mau-8a'
            ),

            new AppMenuItem(
                'Kế hoạch chuyển mẫu',
                'Pages_TrungTamArea_ChuyenPhat',
                'flaticon-truck',
                '/app/main/trung-tam/ke-hoach-chuyen-mau'
            ),
            new AppMenuItem(
                'NhapDuLieu',
                'Pages_DonViArea_NhapKetQua',
                'flaticon-statistics'
                , '', [
                new AppMenuItem(
                    'NhapThongSo',
                    'Pages_DonViArea_NhapKetQua',
                    'la la-flask',
                    '/app/main/don-vi/nhap-thong-so'
                ),
                new AppMenuItem(
                    'NhapKetQua',
                    'Pages_DonViArea_NhapKetQua',
                    'flaticon-clipboard',
                    '/app/main/don-vi/nhap-ket-qua'
                )
            ]
            ),
            /*new AppMenuItem('BaoCao', 'Pages_DonViArea_BaoCao', 'flaticon-folder-2', '', [
                // new AppMenuItem('BaoCaoDinhKy', 'Pages_DonViArea_BaoCao', 'flaticon-list-2', '/app/main/don-vi/bao-cao-dinh-ky'),
                // new AppMenuItem('BaoCaoNhomThietBi', 'Pages_DonViArea_BaoCao', 'flaticon-list-2', '/app/main/don-vi/bao-cao-thiet-bi'),
                // new AppMenuItem('BaoCaoCuoiChuKy', 'Pages_DonViArea_BaoCao', 'flaticon-list-2', '/app/main/don-vi/bao-cao-cuoi-ky'),
                new AppMenuItem(
                    'BaoCaoPhanTich',
                    'Pages_DonViArea_BaoCao',
                    'flaticon-graphic-1',
                    '/app/main/don-vi/bao-cao-phan-tich'
                )
            ]),*/


            // new AppMenuItem(
            //     'ChungChi',
            //     'Pages_DonViArea_ChungChi',
            //     'flaticon-technology-1',
            //     '/app/main/don-vi/chung-chi'
            // ),
            // new AppMenuItem('XacNhanMau', 'Pages_DonViArea', 'flaticon-placeholder', '/app/main/don-vi/xac-nhan-mau'),

            // trung tam areas
            //new AppMenuItem('NhapKetQua', 'Pages_TrungTamArea_NhapKetQua', '	flaticon-edit', '/app/main/trung-tam/ket-qua'),
            /*new AppMenuItem('BaoCao', 'Pages_TrungTamArea_BaoCao', 'flaticon-folder-2', '', [
                // new AppMenuItem('BaoCaoDinhKy', 'Pages_TrungTamArea', 'flaticon-list-2', '/app/main/trung-tam/bao-cao-dinh-ky'),
                // new AppMenuItem('BaoCaoNhomThietBi', 'Pages_TrungTamArea', 'flaticon-list-2', '/app/main/trung-tam/bao-cao-thiet-bi'),
                // new AppMenuItem('BaoCaoCuoiChuKy', 'Pages_TrungTamArea', 'flaticon-list-2', '/app/main/trung-tam/bao-cao-cuoi-ky'),
                new AppMenuItem(
                    'BaoCaoPhanTich',
                    'Pages_TrungTamArea',
                    ' flaticon-graphic-1',
                    '/app/main/trung-tam/bao-cao-phan-tich'
                ),
                new AppMenuItem(
                    'Kết quả nhập liệu',
                    'Pages_TrungTamArea',
                    'flaticon-list-3',
                    '/app/main/trung-tam/trung-tam-ket-qua-nhap-lieu'
                )
            ]),*/
            new AppMenuItem('NhatKiTheoDoi', 'Pages_TrungTamArea_NhatKiTheoDoi', 'flaticon-notes', '', [
                // new AppMenuItem('BaoCaoDinhKy', 'Pages_TrungTamArea', 'flaticon-list-2', '/app/main/trung-tam/bao-cao-dinh-ky'),
                // new AppMenuItem('BaoCaoNhomThietBi', 'Pages_TrungTamArea', 'flaticon-list-2', '/app/main/trung-tam/bao-cao-thiet-bi'),
                // new AppMenuItem('BaoCaoCuoiChuKy', 'Pages_TrungTamArea', 'flaticon-list-2', '/app/main/trung-tam/bao-cao-cuoi-ky'),
                new AppMenuItem(
                    'DanhSachKhanhHang',
                    'Pages_TrungTamArea',
                    'flaticon-graphic-1',
                    '/app/main/trung-tam/theo-doi-danh-sach-trung-tam'
                ),
                new AppMenuItem(
                    'LichSuThayDoiThongSo',
                    'Pages_TrungTamArea',
                    'flaticon-list-3',
                    '/app/main/trung-tam/theo-doi-lich-su-nhap-thong-so'
                ),
                new AppMenuItem(
                    'TongHopThongSo',
                    'Pages_TrungTamArea',
                    'flaticon-calendar-2',
                    '/app/main/trung-tam/tong-hop-thong-so'
                ),
                new AppMenuItem(
                    'DonViKetQua',
                    'Pages_TrungTamArea',
                    'flaticon-calendar-2',
                    '/app/main/trung-tam/theo-doi-ket-qua-don-vi'
                ),
            ]),
            new AppMenuItem(
                'Báo cáo',
                'Pages.BaoCaoNgoaiKiem.TrungTam',
                'flaticon-graphic',
                '/app/main/bao-cao-ket-qua-trung-tam'
            ),
            new AppMenuItem(
                'Báo cáo',
                'Pages.BaoCaoNgoaiKiem.PhongChuyenMon',
                'flaticon-graphic',
                '/app/main/bao-cao-ket-qua-phong-chuyen-mon'
            ),
            new AppMenuItem(
                'Báo cáo',
                'Pages.BaoCaoNgoaiKiem.BenhVien',
                'flaticon-graphic',
                '/app/main/bao-cao-ket-qua-benh-vien'
            ),
            // new AppMenuItem('KeToan', 'Pages_TrungTamArea_KeToan', 'flaticon-calendar', '/app/main/trung-tam/ke-toan'),
            // new AppMenuItem(
            //     'ChuyenPhat',
            //     'Pages_TrungTamArea_ChuyenPhat',
            //     'flaticon-truck',
            //     '/app/main/trung-tam/chuyen-phat'
            // ),
            // new AppMenuItem(
            //     'ChungChi',
            //     'Pages_TrungTamArea_ChungChi',
            //     'flaticon-clipboard',
            //     '/app/main/trung-tam/chung-chi'
            // ),

            new AppMenuItem('Category', '', 'flaticon-layers', '', [
                new AppMenuItem(
                    'Instrument',
                    'Pages.QuanLy.DanhMuc.ThietBiXetNghiem',
                    'flaticon-statistics',
                    '/app/main/category/instrument'
                ),
                new AppMenuItem(
                    'ReagentSuppliler',
                    'Pages.QuanLy.DanhMuc.NhaCungCapHoaChat',
                    'flaticon-network',
                    '/app/main/category/reagent-suppliler'
                ),
                new AppMenuItem(
                    'UnitOfMeasurement',
                    'Pages.QuanLy.DanhMuc.DonViDo',
                    'flaticon-time-1',
                    '/app/main/category/unit-of-measurement'
                ),
                new AppMenuItem(
                    'Method',
                    'Pages.QuanLy.DanhMuc.PhuongPhap.XetNghiem',
                    'flaticon-light',
                    '/app/main/category/method'
                ),
                new AppMenuItem(
                    'Antibiotic',
                    'Pages.QuanLy.DanhMuc.KhangSinh',
                    'flaticon-warning-sign',
                    '/app/main/category/antibiotic'
                ),
                new AppMenuItem(
                    'Species',
                    'Pages.QuanLy.DanhMuc.ViKhuan',
                    'flaticon-confetti',
                    '/app/main/category/species'
                ),
                new AppMenuItem(
                    'MethodUnitSetting',
                    'Pages.QuanLy.DanhMuc.PhuongPhap.XetNghiem',
                    'flaticon-light',
                    '/app/main/category/method-unit-setting'
                ),
                new AppMenuItem(
                    'Parameter',
                    'Pages.QuanLy.DanhMuc.PhuongPhap.XetNghiem',
                    'flaticon-folder-1',
                    '/app/main/category/parameter'
                ),
                /* new AppMenuItem(
                    'HinhThucVanChuyen',
                    'Pages.QuanLy.DanhMuc',
                    'flaticon-truck',
                    '/app/main/category/hinh-thuc-van-chuyen'
                ), */
                new AppMenuItem(
                    'DichVuVanChuyen',
                    'Pages.QuanLy.DanhMuc.DonGiaDichVuVanChuyen',
                    'flaticon-truck',
                    '/app/main/category/delivery-price'
                ),
                new AppMenuItem(
                    'QuanLyChuongTrinhXetNghiem',
                    'Pages.QuanLy.DanhMuc',
                    'flaticon-list-3',
                    '/app/main/category/programme'
                ),
                new AppMenuItem(
                    'Category1',
                    'Pages.QuanLy.DanhMuc',
                    'flaticon-suitcase',
                    '/app/main/category/category'
                )
            ]),
            //new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            //new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [
                new AppMenuItem(
                    'OrganizationUnits',
                    'Pages.Administration.OrganizationUnits',
                    'flaticon-map',
                    '/app/admin/organization-units'
                ),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),
                new AppMenuItem(
                    'AuditLogs',
                    'Pages.Administration.AuditLogs',
                    'flaticon-folder-1',
                    '/app/admin/auditLogs'
                ),
                new AppMenuItem(
                    'Maintenance',
                    'Pages.Administration.Host.Maintenance',
                    'flaticon-lock',
                    '/app/admin/maintenance'
                ),
                new AppMenuItem(
                    'Subscription',
                    'Pages.Administration.Tenant.SubscriptionManagement',
                    'flaticon-refresh',
                    '/app/admin/subscription-management'
                ),
                new AppMenuItem(
                    'VisualSettings',
                    'Pages.Administration.UiCustomization',
                    'flaticon-medical',
                    '/app/admin/ui-customization'
                ),
                new AppMenuItem(
                    'Settings',
                    'Pages.Administration.Host.Settings',
                    'flaticon-settings',
                    '/app/admin/hostSettings'
                ),
                new AppMenuItem(
                    'Settings',
                    'Pages.Administration.Tenant.Settings',
                    'flaticon-settings',
                    '/app/admin/tenantSettings'
                )
            ])
            //new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components')
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
            hideMenuItem = true;
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
